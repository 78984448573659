<template>
  <div class="group">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList" class="recommend_box">
        <div class="group_name">爆款拼团</div>
        <div class="group_name_y">
          <div v-for="(item,index) in dataAll.adApp" :key="index" class="group_top_img">
            <img :src="item.url" alt="">
          </div>
        </div>
        <!--list-->
        <skeleton v-if="showLoading" type="dataList" />
        <div v-else>
          <div v-for="(item,index) in list" :key="index" class="list-item" @click="btnLink(item)">
            <img :src="item.goodsImg" alt="">
            <div class="left">
              <div class="top">{{ item.goodsName }}</div>
              <div class="center">
                <span>官网价：{{ item.originPrice }}</span>
              </div>
              <div class="center_teamPrice">
                <span>¥{{ item.teamPrice }}</span>
              </div>
              <div class="bottom">
                <span class="needer">{{ item.needer }}人团</span>
                <span class="needer_go">立即开团</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="list.length === 0" class="list_no">暂无数据</div>

      </div>
    </mescroll>

  </div>
</template>

<script>
import { assemble } from '@/services/api'
import mescroll from '@/component/fresh_scroll/mescroll'
import skeleton from '@/component/skeleton/index'

export default {
  name: 'Group',
  components: {
    mescroll,
    skeleton
  },
  data() {
    return {
      showLoading: false,
      channel: 7, // 渠道ID
      dataAll: '',
      list: '',
      mescrollDown: {
        use: true,
        top: '0px',
        bottom: '0'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      }
    }
  },
  mounted() {
    this.gitAssemble()
  },
  methods: {
    gitAssemble: function() {
      this.showLoading = true
      const data = {
        channel: this.channel,
        page: 1,
        pageSize: 10
      }
      assemble(data).then(res => {
        if (Number(res.code) === 200) {
          this.dataAll = res.data
          this.list = res.data.item
          this.showLoading = false
        }
      })
    },
    mescrollInit(data) {
      this.mescroll = data
    },
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet: function(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const data = {
        channel: this.channel,
        page: pageNum,
        pageSize: pageSize
      }
      assemble(data).then(res => {
        if (Number(res.code) === 200) {
          successCallback(res.data.item)
        }
      })
    },
    btnLink(item) {
      const obj = {
        product_type: 7, // 暂时借口没有返回 这个字段 product_type
        goodId: item.goodsId,
        skuId: item.skuId,
        id: item.id
      }
      this.$store.dispatch('godetail', obj)
    }

  }
}
</script>

<style lang="less" scoped>
.group {
  .recommend_box {
    background: #f6f6f6;
  }

  background: #f6f6f6;

  .group_name {
    width: 100%;
    height: 80px;
    background: #03b9ff;
    color: #fff;
    font-size: 20px;
    line-height: 40px;
  }

  .group_name_y {
    width: 100%;
    height: 100px;
    border-radius: 0 0 50% 50%;
    background: #03b9ff;
    display: flex;

    .group_top_img {
      width: 90%;
      height: 140px;
      margin: -40px auto;
      border-radius: 6px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  .list_no {
    text-align: center;
    padding: 12px;
  }

  .list-item {
    background: #ffffff;
    width: 94%;
    height: 110px;
    display: flex;
    align-items: center;
    margin: 10px auto;
    border-radius: 10px;

    img {
      width: 25%;
      height: 90%;
      margin-left: 20px;
    }

    .left {
      width: 100%;
      align-items: center;
      text-align: left;
      margin-left: 12px;
      position: relative;

      .top {
        padding-right: 12px;
        box-sizing: border-box;
        text-align: left;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .center {
        margin-top: 28px;
        font-size: 10px;
        text-decoration: line-through;
        color: #bbb;
      }

      .center_teamPrice {
        margin-top: 6px;
        color: #ff5e00;
        font-size: 16px;
      }

      .bottom {
        border: 1px solid #ff5e00;
        font-size: 10px;
        bottom: 6px;
        position: absolute;
        right: 12px;
        padding: 7px 0 7px 7px;
        box-sizing: border-box;
        border-radius: 14px;
        overflow: hidden;
        color: #ff5e00;

        .needer {
          padding: 7px 7px 7px 7px;
        }

        .needer_go {
          background: #ff5e00;
          padding: 7px;
          box-sizing: border-box;
          color: #fff;
        }
      }
    }

  }
}

</style>
